require('../css/index/index.scss');
import $ from 'jquery';

//redux store
import configureStore from '../../App/js/dataStore/configureStore';

import ImageColumnLoader from '../../App/js/imageColumns/imageColumnLoader';


var userCheckInterval; //interval to check if the user info is loaded from server
var imgLoader = new ImageColumnLoader("ColumnContainerParent"); //image loading object that can be passed a parent object ID to create inside

const store = configureStore();
let user=store.getState().reducer_user.user;

$(function(){
	userCheckInterval=setInterval(function(){
		if(user.isFinished()){
			clearInterval(userCheckInterval);
			indexInit();
		}
	},20);
});


function indexInit(){
	
	//$("#BannerSignupContainer").html(window.screen.width);
	//$("#BannerLoginContainer").html(window.screen.height);

	//crete columns and get GET request input
	imgLoader.createColumns();

	let searchInput=$("#GetSearchText").val();
	let sortType=$("#GetSortType").val();
	let sortOrder=$("#GetSortOrder").val();
	imgLoader.setSearchInput(searchInput);
	imgLoader.setSortTypeInput(sortType);
	imgLoader.setSortOrderInput(sortOrder);

	//search button function
	$('#BannerSearchSubmit').on("click",function(){imgLoader.newSearch(randKey());});
	$('#BannerSearchText').on('keypress',function(e) {
		if(e.which == 13){
			let key=randKey();
			console.log("NEW KEY="+key);
			imgLoader.newSearch(key);
		}
	});
	//Start initial interval
	imgLoader.newSearch(randKey());
}


function randKey(length=5){
	let key=Math.floor(Math.random()*Math.pow(10,length));
	return key;
}




